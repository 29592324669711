export const Table = ({ rows }) => {
  return <table>
    <thead>
      <tr>
        <th>State</th>
        <th>State Tax</th>
        <th>Total Tax</th>
      </tr>
    </thead>
    <tbody>
      {rows.map((row) => <tr>
        <td>{row.state}</td>
        <td>{row.stateTax}</td>
        <td>{row.totalTax}</td>
      </tr>
      )}
    </tbody>
  </table>
}