import React, { useState } from "react";
import { Table } from "./Table";
import './App.css';

const taxData = {
  federalTaxBrackets: [
    { rate: 10, range: [0, 11000] },
    { rate: 12, range: [11001, 44725] },
    { rate: 22, range: [44726, 95375] },
    { rate: 24, range: [95376, 182100] },
    { rate: 32, range: [182101, 231250] },
    { rate: 35, range: [231251, 578125] },
    { rate: 37, range: [578126, Infinity] },
  ],
  stateTaxes: {
    "Alabama": { rate: 5, brackets: [0, 3000, 6000] },
    "Alaska": { rate: 0, brackets: [] }, // No state income tax
    "Arizona": { rate: 4.5, brackets: [0, 26736, 53472, 160000] },
    "Arkansas": { rate: 5.5, brackets: [0, 4300, 8500, 12600, 21100, 35000] },
    "California": { rate: 10, brackets: [0, 8809, 20883, 32960, 45753, 57824, 295373, 354445, 590742] },
    "Colorado": { rate: 4.4, brackets: [0, Infinity] },
    "Connecticut": { rate: 6.99, brackets: [0, 10000, 50000, 100000, 200000, 250000, 500000] },
    "Delaware": { rate: 6.6, brackets: [0, 2000, 5000, 10000, 20000, 25000, 60000] },
    "Florida": { rate: 0, brackets: [] }, // No state income tax
    "Georgia": { rate: 5.75, brackets: [0, 1000, 3000, 5000, 7000, 10000] },
    "Hawaii": { rate: 11, brackets: [0, 2400, 4800, 9600, 14400, 19200, 24000, 36000, 48000, 150000, 175000, 200000] },
    "Idaho": { rate: 6.5, brackets: [0, 1548, 3096, 4644, 6192, 9288, 12384, 18576] },
    "Illinois": { rate: 4.95, brackets: [0, Infinity] },
    "Indiana": { rate: 3.23, brackets: [0, Infinity] },
    "Iowa": { rate: 8.53, brackets: [0, 1638, 3276, 6552, 14742, 24570, 32760, 49140, 73710, 110565] },
    "Kansas": { rate: 5.7, brackets: [0, 15000, 30000] },
    "Kentucky": { rate: 5, brackets: [0, Infinity] },
    "Louisiana": { rate: 6, brackets: [0, 12500, 50000, 150000] },
    "Maine": { rate: 7.15, brackets: [0, 21050, 50450] },
    "Maryland": { rate: 5.75, brackets: [0, 1000, 2000, 3000, 100000, 125000, 150000, 250000] },
    "Massachusetts": { rate: 5, brackets: [0, Infinity] },
    "Michigan": { rate: 4.25, brackets: [0, Infinity] },
    "Minnesota": { rate: 9.85, brackets: [0, 29520, 97390, 183340] },
    "Mississippi": { rate: 5, brackets: [0, 5000, 10000] },
    "Missouri": { rate: 5.3, brackets: [0, 108, 1088, 2176, 3264, 4352, 5440, 6528, 7616] },
    "Montana": { rate: 6.75, brackets: [0, 3200, 8200, 13000, 16800, 22100] },
    "Nebraska": { rate: 6.84, brackets: [0, 3360, 20160, 32300] },
    "Nevada": { rate: 0, brackets: [] }, // No state income tax
    "New Hampshire": { rate: 0, brackets: [] }, // No state income tax (only tax on dividends and interest)
    "New Jersey": { rate: 10.75, brackets: [0, 20000, 50000, 70000, 80000, 150000, 500000] },
    "New Mexico": { rate: 5.9, brackets: [0, 5500, 11000, 16000, 21000] },
    "New York": { rate: 10.9, brackets: [0, 8500, 11700, 13900, 21400, 80650, 215400, 1077550] },
    "North Carolina": { rate: 4.75, brackets: [0, Infinity] },
    "North Dakota": { rate: 2.9, brackets: [0, 40375, 97750, 204100, 510300] },
    "Ohio": { rate: 3.99, brackets: [0, 21750, 43450, 86800, 108700] },
    "Oklahoma": { rate: 5, brackets: [0, 1000, 2500, 3750, 4900, 7200] },
    "Oregon": { rate: 9.9, brackets: [0, 3650, 9200, 125000, 250000] },
    "Pennsylvania": { rate: 3.07, brackets: [0, Infinity] },
    "Rhode Island": { rate: 5.99, brackets: [0, 66200, 150550] },
    "South Carolina": { rate: 7, brackets: [0, 3070, 6140, 9220, 12290, 15370] },
    "South Dakota": { rate: 0, brackets: [] }, // No state income tax
    "Tennessee": { rate: 0, brackets: [] }, // No state income tax
    "Texas": { rate: 0, brackets: [] }, // No state income tax
    "Utah": { rate: 4.85, brackets: [0, Infinity] },
    "Vermont": { rate: 8.75, brackets: [0, 41050, 99200, 206950] },
    "Virginia": { rate: 5.75, brackets: [0, 3000, 5000, 17000] },
    "Washington": { rate: 0, brackets: [] }, // No state income tax
    "West Virginia": { rate: 6.5, brackets: [0, 10000, 25000, 40000, 60000] },
    "Wisconsin": { rate: 7.65, brackets: [0, 12120, 24240, 266930] },
    "Wyoming": { rate: 0, brackets: [] }, // No state income tax
  },
  socialSecurityRate: 6.2,
  medicareRate: 1.45,
  additionalMedicareRate: 0.9,
  socialSecurityLimit: 160200,
};

function calculateFederalTax(income) {
  let tax = 0;
  for (let bracket of taxData.federalTaxBrackets) {
    if (income > bracket.range[0]) {
      const taxableIncome = Math.min(income, bracket.range[1]) - bracket.range[0];
      tax += (taxableIncome * bracket.rate) / 100;
    }
  }
  return tax;
}

function calculateStateTax(income, state) {
  const stateTax = taxData.stateTaxes[state] || { rate: 0, brackets: [] };
  if (stateTax.rate === 0) return 0;

  let tax = 0;
  for (let i = 0; i < stateTax.brackets.length; i++) {
    if (income > stateTax.brackets[i]) {
      const taxableIncome = Math.min(income, stateTax.brackets[i + 1] || income) - stateTax.brackets[i];
      tax += (taxableIncome * stateTax.rate) / 100;
    }
  }
  return tax;
}

function calculateFICATax(income) {
  const socialSecurityTax = Math.min(income, taxData.socialSecurityLimit) * (taxData.socialSecurityRate / 100);
  const medicareTax = income * (taxData.medicareRate / 100);
  const additionalMedicareTax = income > 200000 ? (income - 200000) * (taxData.additionalMedicareRate / 100) : 0;
  return socialSecurityTax + medicareTax + additionalMedicareTax;
}

function App() {
  const [income, setIncome] = useState("");
  const [state, setState] = useState("California");
  const [result, setResult] = useState(null);

  const handleSubmit = (e) => {
    e.preventDefault();
    const _income = parseFloat(income);
    const federalTax = calculateFederalTax(_income);
    const stateTax = calculateStateTax(_income, state);
    const ficaTax = calculateFICATax(_income);
    const states = [];

    Object.keys(taxData.stateTaxes).forEach((state) => {
      const stateTax = calculateStateTax(_income, state);
      const totalTax = federalTax + stateTax + ficaTax;
      states.push({
        state,
        stateTax,
        totalTax
      });
    });

    setResult({
      federalTax,
      stateTax,
      ficaTax,
      totalTax: federalTax + stateTax + ficaTax,
      states
    });
  };

  return (
    <div className="App">
      <h1>Tax Calculator</h1>
      <form className="grid" onSubmit={handleSubmit}>
        <div>
          <label>Income</label>
          <input
            type="number"
            value={income}
            onChange={(e) => setIncome(e.target.value)}
            required
          />
        </div>
        <div>
          <label>State</label>
          <select
            value={state}
            onChange={(e) => setState(e.target.value)}
          >
            {Object.keys(taxData.stateTaxes).map((state) => (
              <option key={state} value={state}>{state}</option>
            ))}
          </select>
        </div>
        <button type="submit">Calculate</button>
      </form>

      {result && (
        <div className="result">
          <h2>Results:</h2>
          <div className="grid">
            <p>Federal Tax: ${result.federalTax.toFixed(2)}</p>
            <p>State Tax: ${result.stateTax.toFixed(2)}</p>
            <p>FICA Tax: ${result.ficaTax.toFixed(2)}</p>
            <p>Total Tax: ${result.totalTax.toFixed(2)}</p>
          </div>
          <Table rows={result.states} />
        </div>

      )}
    </div>
  );
}

export default App;
